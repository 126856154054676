import {
	AddAlert,
	BrandingWatermarkRounded,
	Category,
	Chat,
	ConnectWithoutContact,
	// CurrencyExchangeSharp,
	DashboardRounded,
	Group,
	GroupAdd,
	KeyboardArrowDown,
	LiveHelp,
	MenuBook,
	Newspaper,
	NotificationsActive,
	// DashboardRounded,
	Person,
	PersonAdd,
	PhonelinkSetup,
	Settings,
	Style,
	TrendingUpRounded,
	ViewCarouselRounded,
	WhatshotRounded,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { advertisementSlice } from 'redux/advertise/advertiseSlice';
import { updatePermission } from 'redux/auth/authSlice';
import { bankAccountSlice } from 'redux/bankAccount/bankSlice';
import { bannerSlice } from 'redux/banner/bannerSlice';
import { brandSlice } from 'redux/brand/brandSlice';
import { categorySlice } from 'redux/category/categorySice';
import { clientSlice } from 'redux/client/clientSlice';
import { contactSlice } from 'redux/contact/contactSlice';
import { couponSlice } from 'redux/coupon/couponSlice';
import { RootState } from 'redux/store';
import { tagSlice } from 'redux/tags/tagSlice';
import { usersSlice } from 'redux/users/usersSlice';

import getDashboardData from '../../api/dashboard';
import styles from './index.module.scss';

type TSection = {
	name: string;
	key: string;
	// eslint-disable-next-line
	icon?: JSX.Element;
	path?: string;
	hasNewNotification?: boolean;
	subSections?: {
		name: string;
		key: string;
		// eslint-disable-next-line
		icon: JSX.Element;
		path?: string;
		hasNewNotification?: boolean;
	}[];
};
const LinkItem = ({
	section,
	onPageChange,
}: {
	section: TSection;
	onPageChange: () => void;
}) => {
	const location = useLocation();
	if (!section.path) {
		return null;
	}
	return (
		<NavLink
			style={({ isActive }) =>
				isActive
					? {
							color: '#ba54f5',
							background: '#fff',
							// pointerEvents: 'none',
					  }
					: {}
			}
			onClick={onPageChange}
			to={section.path}
			key={section.path}
			className={`${styles.list} list`}
		>
			{section.icon}
			<span style={{ position: 'relative' }}>
				{section.name}
				{section?.hasNewNotification ? (
					<span
						className=''
						style={{
							position: 'absolute',
							height: '10px',
							width: '10px',
							borderRadius: '50%',
							backgroundColor:
								location.pathname === section.path ? '#FF3333' : '#FFFFFF',
							right: '-10px',
							top: '2px',
						}}
					/>
				) : (
					<span />
				)}
			</span>
		</NavLink>
	);
};

const Sidebar = () => {
	const { userData } = useSelector((state: RootState) => state.auth);

	const { dashboardData } = useSelector((state: RootState) => state.dashboard);
	const dispatch = useDispatch();
	const permissions: string[] = ['Dashboard']; // Dashboard is visible to all roles.

	if (userData) {
		userData?.user?.permissions?.forEach((item: any) =>
			permissions.push(item.name)
		);
	}
	useEffect(() => {
		dispatch(getDashboardData());
		dispatch(updatePermission(userData?.user?.id));
	}, []);
	const resetCurrentPage = () => {
		dispatch(bankAccountSlice.actions.setCurrentPage(1));
		dispatch(usersSlice.actions.setCurrentPage(1));
		dispatch(advertisementSlice.actions.setCurrentPage(1));
		dispatch(bannerSlice.actions.setCurrentPage(1));
		dispatch(brandSlice.actions.setCurrentPage(1));
		dispatch(categorySlice.actions.setCurrentPage(1));
		dispatch(clientSlice.actions.setCurrentPage(1));
		dispatch(contactSlice.actions.setCurrentPage(1));
		dispatch(couponSlice.actions.setCurrentPage(1));
		dispatch(tagSlice.actions.setCurrentPage(1));
	};
	const sections: TSection[] = [
		{
			name: 'Analytics',
			key: 'analytics',
			hasNewNotification: dashboardData?.subscribe,
			subSections: [
				{
					name: 'Dashboard',
					key: 'Dashboard',
					icon: <DashboardRounded />,
					path: '/',
				},
				{
					name: 'User Management',
					key: 'User Management',
					icon: <Person />,
					path: '/user-management',
				},
				{
					name: 'Subscriptions',
					key: 'Subscriptions',
					icon: <Newspaper />,
					path: '/news-letters',
					hasNewNotification: dashboardData?.subscribe,
				},
			],
		},
		{
			name: 'Secondary feature management',
			key: 'secondary-feature-management',
			hasNewNotification: dashboardData?.chatAlert,
			subSections: [
				{
					name: 'Notification Management',
					key: 'Notification Management',
					icon: <NotificationsActive />,
					path: '/notification-management',
				},
				{
					name: 'Bread Crumbs Management',
					key: 'Bread Crumbs Management',
					icon: <PhonelinkSetup />,
					path: '/bread-crumbs',
				},
				{
					name: 'Chat Management',
					key: 'Chat Management',
					icon: <Chat />,
					path: '/chat-management',
					hasNewNotification: dashboardData?.chatAlert,
				},
				{
					name: 'Tag Management',
					key: 'Tag Management',
					icon: <Style />,
					path: '/tag-management',
				},
				{
					name: 'Trend Management',
					key: 'Trend Management',
					icon: <TrendingUpRounded />,
					path: '/trending-management',
				},
			],
		},
		{
			name: 'Primary feature management',
			key: 'primary-feature-management',
			hasNewNotification: dashboardData?.cashbackAlert,
			subSections: [
				{
					name: 'Category Management',
					key: 'Category Management',
					icon: <Category />,
					path: '/category-management',
				},
				{
					name: 'Brands Management',
					key: 'Brands Management',
					icon: <BrandingWatermarkRounded />,
					path: '/brand-management',
				},
				{
					name: 'Banner Management',
					key: 'Banner Management',
					icon: <ViewCarouselRounded />,
					path: '/banner-management',
				},
				{
					name: 'Coupon Management',
					key: 'Coupon Management',
					icon: <WhatshotRounded />,
					path: '/coupon-management',
				},
				// {
				// 	name: 'Cashback Management',
				// 	key: 'Cashback Management',
				// 	icon: <CurrencyExchangeSharp />,
				// 	path: '/cashback-management',
				// 	hasNewNotification: dashboardData?.cashbackAlert
				// },
				{
					name: 'Page Seo Management',
					key: 'Page Seo Management',
					icon: <MenuBook />,
					path: '/page-seo',
				},
			],
		},
		{
			name: 'Affiliate management',
			key: 'affiliate-management',
			subSections: [
				{
					name: 'Partner Name Management',
					key: 'Partner Name Management',
					icon: <Group />,
					path: '/affiliate-management',
				},
			],
		},
		{
			name: 'Contact and setting',
			key: 'contact-and-setting',
			hasNewNotification: dashboardData?.contactUs || dashboardData?.ads,
			subSections: [
				{
					name: 'Advertisement Management',
					key: 'Advertisement Management',
					icon: <AddAlert />,
					path: '/advertisement-management',
					hasNewNotification: dashboardData?.ads,
				},
				{
					name: 'Contact Management',
					key: 'Contact Management',
					icon: <ConnectWithoutContact />,
					path: '/contacts-management',
					hasNewNotification: dashboardData?.contactUs,
				},
				{
					name: 'Settings Management',
					key: 'Settings Management',
					icon: <Settings />,
					path: '/settings-management',
				},
				{
					name: 'FAQ Management',
					key: 'FAQ Management',
					icon: <LiveHelp />,
					path: '/faq-management',
				},
				{
					name: 'Roles Management',
					key: 'Roles Management',
					icon: <GroupAdd />,
					path: '/roles-management',
				},
				{
					name: 'Country Management',
					key: 'Country Management',
					icon: <MenuBook />,
					path: '/country-management',
				},
			],
		},
		{
			name: 'About Us and Clients ',
			key: 'about-us-and-clients',
			subSections: [
				{
					name: 'About Us Management',
					key: 'About Us Management',
					icon: <MenuBook />,
					path: '/about-us-management',
				},
				{
					name: 'Our Clients',
					key: 'Our Clients',
					icon: <PersonAdd />,
					path: '/client-management',
				},
			],
		},
	];
	const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(0);
	const [openedSectionIndex, setOpenedSectionIndex] = useState<number>(-1);
	const handlePageChange = (sectionIndex: number) => {
		setSelectedSectionIndex(sectionIndex);
		setOpenedSectionIndex(sectionIndex);
		resetCurrentPage();
	};
	const handleSectionOpen = (index: number) => {
		setOpenedSectionIndex(prev => (prev === index ? -1 : index));
	};
	const isSectionOpen = (index: number) => openedSectionIndex === index;
	const location = useLocation();
	useEffect(() => {
		const activeIndex = sections.findIndex(section =>
			section.subSections
				? section?.subSections.find(sub => sub.path === location.pathname) !==
				  undefined
				: section.path === location.pathname
		);
		setSelectedSectionIndex(activeIndex);
		setOpenedSectionIndex(activeIndex);
	}, []);
	return (
		<div className={styles.Sidebar}>
			{sections.map((section, index) => (
				<div className='' key={section.key}>
					<div
						className={`${styles.dropdown} ${
							index === selectedSectionIndex ? styles.isDropDownActive : ''
						}`}
						role='button'
						tabIndex={-1}
						onKeyDown={() => handleSectionOpen(index)}
						onClick={() => handleSectionOpen(index)}
					>
						<div style={{ position: 'relative' }}>
							{section.name}
							{section?.hasNewNotification && (
								<span
									className=''
									style={{
										position: 'absolute',
										height: '10px',
										width: '10px',
										borderRadius: '50%',
										backgroundColor: '#FFFFFF',
										right: '-10px',
										top: '2px',
									}}
								/>
							)}
						</div>
						<KeyboardArrowDown
							className={`${styles.dropdownIcon} ${
								isSectionOpen(index) ? styles.isActive : ''
							}`}
						/>
					</div>
					<div
						className={`${styles.subSections} ${
							isSectionOpen(index) ? styles.isSubSectionActive : ''
						}`}
					>
						<div className={styles.sectionContainer}>
							{section.subSections
								? section.subSections?.map(
										sub =>
											permissions?.includes(sub.key) && (
												<LinkItem
													onPageChange={() => handlePageChange(index)}
													section={sub}
													key={sub.key}
												/>
											)
								  )
								: permissions?.includes(section.key) && (
										<LinkItem
											onPageChange={() => handlePageChange(index)}
											section={section}
										/>
								  )}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Sidebar;
