import './styles/main.scss';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import theme from 'utils/mui-theme';

import ImageResize from 'quill-image-resize-module-react';
import Quill from 'quill';
import App from './App';
import reportWebVitals from './reportWebVitals';

const persistor = persistStore(store);
Sentry.init({
	dsn: process.env.REACT_APP_DSN,
	integrations: [new BrowserTracing()],
	environment: process.env.REACT_APP_ENVIORNMENT,
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 0.5,
});
Quill.register('modules/imageResize', ImageResize);

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<div className='head-line' />
					<App />
				</PersistGate>
			</Provider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
