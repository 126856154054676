import * as yup from 'yup';

const validationSchema = yup.object({
	// category: yup.array().min(1, 'Select atleast one category'),
	brandId: yup.string().required('Brand is required'),
	position: yup.string().required('Position is required'),
	priority: yup.number().min(1, 'Priority should be above 1'),
	link: yup
		.string()
		// .required('Link is required')
		.matches(
			/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/,
			'Link must start with http:// or https://'
		),
	status: yup.number().required('Status is required'),
	banner: yup.string().required('Banner is required'),
	banner_ara: yup.string().when('banner', {
		is: '',
		then: yup.string().required('البانر مطلوب'),
	}),
	imageAlt: yup.string().required('Image Alt is required'),
	imageAltAra: yup.string().required('Image Alt Ara is required'),
	imageTitle: yup.string().required('Image Title is required'),
	imageTitleAra: yup.string().required('Image Title Ara is required'),
});

export default validationSchema;
