/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */

import {
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import { Box } from '@mui/system';
import { AddCountry, getCountryById, UpdateCountry } from 'api/country';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData } from 'redux/country/countrySlice';
import { RootState } from 'redux/store';
import { allValues, isEmpty, usePrompt } from 'utils/propts';
import UploadImage from 'utils/UploadImage';

import FormLable from '../../../components/common/FormLable';
import validationSchema from './validation';

const WithMaterialUI = () => {
	const { singleCountry, loadingBtn } = useSelector(
		(state: RootState) => state.country
	);

	const { id } = useParams();
	const dispatch = useDispatch();

	const [countryImg, setCountryImg] = useState<string>(singleCountry?.flagSlug);

	const formik = useFormik({
		initialValues: {
			name: singleCountry?.translation?.en[0]?.name
				? singleCountry?.translation?.en[0]?.name
				: '',
			name_ara: singleCountry?.translation?.ara[0]?.name
				? singleCountry?.translation?.ara[0]?.name
				: '',
			code: singleCountry ? singleCountry?.code : '',
			mobileCode: singleCountry ? singleCountry?.mobileCode : '',
			currency: singleCountry ? singleCountry?.currency : '',
			status: singleCountry ? (singleCountry?.status === 1 ? 1 : 0) : 0,
			categoryDescription:
				singleCountry?.translation?.en[0].categoryDescription || '',
			categoryDescriptionAra:
				singleCountry?.translation?.ara[0].categoryDescription || '',
			brandDescription:
				singleCountry?.translation?.en[0].brandDescription || '' || '',
			brandDescriptionAra:
				singleCountry?.translation?.ara[0].brandDescription || '',
			flagSlug: singleCountry ? singleCountry?.flagSlug?.split('com/')[1] : '',
		},
		validationSchema,
		onSubmit: values => {
			const backendObject: any = {
				flagSlug: values.flagSlug,
				status: values.status,
				code: values.code,
				mobileCode: values.mobileCode,
				currency: values.currency,
				translation: [
					{
						languageCode: 'en',
						brandDescription: values.brandDescription,
						categoryDescription: values.categoryDescription,
						name: values.name,
					},
					{
						languageCode: 'ara',
						brandDescription: values.brandDescriptionAra,
						categoryDescription: values.categoryDescriptionAra,
						name: values.name_ara,
					},
				],
			};
			if (id) dispatch(UpdateCountry({ backendObject, id }));
			else dispatch(AddCountry(backendObject));
		},
	});
	const [leavePage, setLeavePage] = useState(false);

	const handleImage = (data: string) => {
		if (data) {
			formik.setFieldValue('flagSlug', data.split('com/')[1]);
		}
		setCountryImg(data);
	};

	const setImg = (slug: string) => {
		if (slug === '') {
			formik.setFieldValue('flagSlug', slug);
		}
	};

	useEffect(() => {
		allValues(formik.values)
			? setLeavePage(false)
			: setLeavePage(!isEmpty(formik.values));
	}, [formik.values]);

	usePrompt(leavePage);
	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<FormLable label='Country Name' />
				<Grid container spacing={1}>
					<Grid item xs={6}>
						{/* English version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='name'
							name='name'
							placeholder='Name'
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
					</Grid>
					<Grid item xs={6}>
						{/* Arabic version field */}

						<Input
							fullWidth
							id='name_ara'
							name='name_ara'
							type='text'
							dir='rtl'
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							style={{
								textAlign: 'right',
							}}
							value={formik.values.name_ara}
							onChange={formik.handleChange}
							placeholder='اسم'
							error={formik.touched.name_ara && Boolean(formik.errors.name_ara)}
							helperText={formik.touched.name_ara && formik.errors.name_ara}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormLable label='Country Image' />

						<UploadImage
							aspect={2 / 1}
							logo={countryImg}
							handleSlug={handleImage}
							entity='country'
							formikImg={(s: string) => setImg(s)}
						/>
						<span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.flagSlug &&
								formik.errors.flagSlug &&
								formik.errors.flagSlug}
						</span>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Country Code' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='code'
							name='code'
							placeholder='Country Code'
							value={formik.values.code}
							onChange={formik.handleChange}
							error={formik.touched.code && Boolean(formik.errors.code)}
							helperText={formik.touched.code && formik.errors.code}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Mobile Code' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='mobileCode'
							name='mobileCode'
							placeholder='Mobile Code'
							value={formik.values.mobileCode}
							onChange={formik.handleChange}
							error={formik.touched.mobileCode && Boolean(formik.errors.mobileCode)}
							helperText={formik.touched.mobileCode && formik.errors.mobileCode}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormLable label='Currency' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='currency'
							name='currency'
							placeholder='Currency'
							value={formik.values.currency}
							onChange={formik.handleChange}
							error={formik.touched.currency && Boolean(formik.errors.currency)}
							helperText={formik.touched.currency && formik.errors.currency}
						/>
					</Grid>
				</Grid>
				{/* Brand Logo */}

				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						<FormLable label='Category Description' required={false} />

						<ReactQuill
							theme='snow'
							value={formik.values.categoryDescription}
							onChange={v => formik.setFieldValue('categoryDescription', v)}
						/>
					</Grid>

					<Grid item xs={6}>
						<FormLable label='Category Description Arabic' required={false} />

						<ReactQuill
							theme='snow'
							value={formik.values.categoryDescriptionAra}
							onChange={v => formik.setFieldValue('categoryDescriptionAra', v)}
						/>
					</Grid>
				</Grid>

				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						<FormLable label='Brand Description' required={false} />

						<ReactQuill
							theme='snow'
							value={formik.values.brandDescription}
							onChange={v => formik.setFieldValue('brandDescription', v)}
						/>
					</Grid>

					<Grid item xs={6}>
						<FormLable label='Brand Description Arabic' required={false} />

						<ReactQuill
							theme='snow'
							value={formik.values.brandDescriptionAra}
							onChange={v => formik.setFieldValue('brandDescriptionAra', v)}
						/>
					</Grid>
				</Grid>

				{/* Status */}

				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active<span style={{ margin: '2px' }}>/</span>صالح
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive<span style={{ margin: '2px' }}>/</span>غير صالح
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const CountryAdd = () => {
	const { loading } = useSelector((state: RootState) => state.country);
	const { isAdded } = useSelector((state: RootState) => state.country);
	const { isUpdated } = useSelector((state: RootState) => state.country);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/country-management');
			dispatch(clearData());
		}
	}, [isAdded, isUpdated]);
	useEffect(() => {
		if (id) {
			dispatch(getCountryById(id));
		}
	}, []);

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title={id ? 'Edit Country' : 'Add Country'} />
				<Box>
					<Box>
						{loading ? (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)',
								}}
							>
								<Loader />
							</Box>
						) : (
							<WithMaterialUI />
							// <Box>abc</Box>
						)}
					</Box>
				</Box>
			</Container>
		</TableLayout>
	);
};

export default CountryAdd;
