// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum ApiEndPoints {
	adminThreads = 'admin/threads',
	adminThread = 'admin/thread',
	adminConsumerPartners = 'admin/consumer-partners',
	adminUserReport = 'admin/users/export',
	exportNewsLetter = '/admin/news-letters/export',
}

export const ApiParams = {
	getBrandByIdParam: 'seoAndTranslation',
	getCategoryByIdParam: 'seoAndTranslation',
} as const;
