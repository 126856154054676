import * as yup from 'yup';

// const FILE_SIZE = 2000000;
// const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const validationSchema = yup.object({
	brandName: yup
		.string()
		.min(2, 'Enter a valid Brand Name')
		.required('Brand Name is required'),
	brandName_ara: yup
		.string()
		.min(2, 'أدخل اسم علامة تجارية صالحًا')
		.required('اسم العلامة التجارية مطلوب'),
	brandLogo: yup.string().required('Logo is required'),
	brandLogoAra: yup.string().required('Brand Logo is required'),
	status: yup.number().required('Status is required.'),
	seoMetas: yup
		.string()
		.max(255, 'Description must be at most 255 characters')
		.nullable(),
	country: yup.array().min(1, 'select  atleast one country'),
	imageAlt: yup.string().required('Image Alt is required'),
	imageAltAra: yup.string().required('Image Alt Ara is required'),
	imageTitle: yup.string().required('Image Title is required'),
	imageTitleAra: yup.string().required('Image Title Ara is required'),
	seoImageAlt: yup.string().required('SEO  Title Ara is required'),
	seoImageAltAra: yup.string().required('SEO  Title Ara is required'),
	seoImageSlug: yup.string().required('SEO  Image is required'),
	seoImageSlugAra: yup.string().required('SEO  Image is required'),
});

export default validationSchema;
