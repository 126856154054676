/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */

import {
	Box,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { AddBanner, getBannerById, UpdateBanner } from 'api/banners';
import { getAllBrandsWithoutPage } from 'api/brand';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData } from 'redux/banner/bannerSlice';
import { RootState } from 'redux/store';
import UploadImage from 'utils/UploadImage';
import {
	offersSubPageCode,
	couponsSubPageCode,
	bannerCategoryCode,
	bannerBrandCode,
	offersListingPageCode,
	couponsListingPageCode,
} from 'utils/constant';
import FormLable from '../../../components/common/FormLable';
import validationSchema from './validation';

// const useStyles = makeStyles(() => ({
// 	selectedAll: {
// 		backgroundColor: 'rgba(186, 84, 245, 0.08)',
// 		'&:hover': {
// 			backgroundColor: 'rgba(186, 84, 245, 0.12)',
// 		},
// 	},
// }));

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	// const classes = useStyles();
	const allCategorieswithoutPage = useSelector(
		(state: RootState) => state.category.allCategorieswithoutPage
	);

	const allCountry = useSelector((state: RootState) => state.country.allCountry);

	// const AllCountryIds = allCountry.map((item: any) => item.id);

	// const allbrandWithoutPage = useSelector((state: RootState) => state.brand.allbrandWithoutPage);
	const loadingBtn = useSelector((state: RootState) => state.banner.loadingBtn);
	const singleBanner = useSelector(
		(state: RootState) => state.banner.singleBanner
	);
	const { allbrandWithoutPage, loading: isBrandLoading } = useSelector(
		(state: RootState) => state.brand
	);

	// banner image upload progress
	const [imageEn, setImageEn] = useState<string>(
		'' || singleBanner?.translation?.en[0]?.imageSlug?.path
	);
	const [imageAra, setImageAra] = useState<string>(
		'' || singleBanner?.translation?.ara[0]?.imageSlug?.path
	);

	const [selected, setSelected] = useState<string[]>([]);
	useEffect(() => {
		setSelected(
			singleBanner?.id ? singleBanner?.country?.map((each: any) => each?.id) : []
		);
	}, []);

	// image upload progress

	const handleImageEn = (data: string) => {
		setImageEn(data);
	};
	const handleImageAra = (data: string) => {
		setImageAra(data);
	};

	// uploaded path

	const formik = useFormik({
		initialValues: {
			category: singleBanner?.id
				? singleBanner?.categoryMeta?.filter((item: any) => item.status === 1)
						?.length > 0
					? singleBanner?.categoryMeta
							?.filter((item: any) => item.status === 1)
							.map((each: any) => each?.id)
					: ''
				: '',
			categoryId: singleBanner ? singleBanner.categoryId : '',
			countryId: singleBanner ? singleBanner.countryId : '',
			brandId: singleBanner?.brandMeta?.id || '',
			country:
				singleBanner?.id !== ''
					? singleBanner?.country?.map((each: any) => each?.id)
					: [],
			position: singleBanner?.position ? singleBanner?.position : '',
			priority: singleBanner?.priority,
			link: singleBanner?.link ? singleBanner?.link : '',
			// eslint-disable-next-line no-nested-ternary
			status: singleBanner ? (singleBanner?.status === 1 ? 1 : 0) : 0,
			page: singleBanner.page ? singleBanner?.page : 1,
			banner: singleBanner
				? singleBanner?.translation?.en[0]?.imageSlug?.slug
				: [],
			banner_ara: singleBanner
				? singleBanner?.translation?.ara[0]?.imageSlug?.slug
				: [],

			imageSlug: singleBanner
				? singleBanner?.translation?.en[0]?.seoImageSlug?.slug
				: '',
			imageSlugAra: singleBanner
				? singleBanner?.translation?.ara[0]?.seoImageSlug?.slug
				: '',
			imageAlt: singleBanner ? singleBanner?.translation?.en[0]?.imageAlt : '',
			imageAltAra: singleBanner ? singleBanner?.translation?.ara[0]?.imageAlt : '',
			imageTitle: singleBanner ? singleBanner?.translation?.en[0]?.imageTitle : '',
			imageTitleAra: singleBanner
				? singleBanner?.translation?.ara[0]?.imageTitle
				: '',
			subPage: singleBanner.subPage ? singleBanner?.subPage : null,
		},

		// singleBanner ? singleBanner?.translation?.en[0]?.imageSlug?.slug :
		validationSchema,
		onSubmit: values => {
			const backendObject: any = {
				// categories: values.category,
				countryId: Array.isArray(values.country)
					? values.country[0]
					: values.country,
				categoryId: Array.isArray(values.category)
					? values.category[0]
					: values.category,
				brandId: values.brandId,
				position: values.position,
				link: values.link,
				status: values.status,
				// countries: values.country,
				page: values.page,
				subPage: values.subPage,
				priority: values.priority ? Number(values.priority) : undefined,
				translation: [
					{
						languageCode: 'en',
						imageSlug: values.banner,
						seoImageSlug: values.imageSlug || values.banner,
						imageAlt: values.imageAlt,
						imageTitle: values.imageTitle,
					},
					{
						languageCode: 'ara',
						imageAlt: values.imageAltAra,
						imageSlug: values.banner_ara ? values.banner_ara : values.banner,
						imageTitle: values.imageTitleAra,
					},
				],
			};
			if (id) {
				dispatch(UpdateBanner({ id, backendObject }));
			} else {
				dispatch(AddBanner(backendObject));
			}
		},
	});

	const isSubPageVisible =
		formik?.values?.page === bannerCategoryCode ||
		formik?.values?.page === bannerBrandCode;
	const isCategoryVisible = formik?.values?.page === bannerCategoryCode;
	// const [leavePage, setLeavePage] = useState(false);

	// useEffect(() => {
	// 	allValues(formik.values)
	// 		? setLeavePage(false)
	// 		: setLeavePage(!isEmpty(formik.values));
	// }, [formik.values]);

	// usePrompt(leavePage);

	const setEnImg = (slug: string) => {
		formik.setFieldValue('banner', slug);
	};
	const setAraImg = (slug: string) => {
		formik.setFieldValue('banner_ara', slug);
	};

	const handleChange = (event: any) => {
		const { value } = event.target;
		setSelected(value);
		formik.setFieldValue('country', value);
	};

	// const isAllSelected =
	// 	allCountry.length > 0 && selected?.length === allCountry?.length;
	useEffect(() => {
		if (selected.length > 0) {
			dispatch(
				getAllBrandsWithoutPage({
					page: 1,
					countries: selected || [],
					addCoupon: true,
				})
			);
		}
	}, [selected]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				{/* Category */}
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='English' required={false} />
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Arabic' required={false} textAlign='right' />
					</Grid>
				</Grid>
				<FormLable label='Page' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.page && Boolean(formik.errors.page)}
						>
							<Select
								fullWidth
								size='small'
								id='page'
								name='page'
								displayEmpty
								value={formik.values.page}
								input={<OutlinedInput />}
								onChange={e => {
									formik.handleChange(e);
									if (e.target.value !== 2) {
										formik.setFieldValue('category', '');
										formik.setFieldValue('subPage', null);
									}
								}}
								error={formik.touched.page && Boolean(formik.errors.page)}
							>
								<MenuItem selected={formik.values.page === 1} value={1}>
									Home
								</MenuItem>
								<MenuItem selected={formik.values.page === 2} value={2}>
									Category
								</MenuItem>
								<MenuItem selected={formik.values.page === 3} value={3}>
									Brand
								</MenuItem>
								<MenuItem
									selected={formik.values.page === offersListingPageCode}
									value={offersListingPageCode}
								>
									Offers Listing
								</MenuItem>
								<MenuItem
									selected={formik.values.page === couponsListingPageCode}
									value={couponsListingPageCode}
								>
									Coupons Listing
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.page && formik.errors.page}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>

				{isSubPageVisible && (
					<>
						<FormLable label='Sub Page' />
						<Grid container rowSpacing={3} columnSpacing={1}>
							<Grid item xs={12}>
								<FormControl
									sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
									error={formik.touched.subPage && Boolean(formik.errors.subPage)}
								>
									<Select
										size='small'
										fullWidth
										id='position'
										name='subPage'
										displayEmpty
										value={formik.values.subPage}
										input={<OutlinedInput />}
										onChange={formik.handleChange}
										error={formik.touched.subPage && Boolean(formik.errors.subPage)}
									>
										<MenuItem
											selected={formik.values.subPage === offersSubPageCode}
											value={offersSubPageCode}
										>
											Offers
										</MenuItem>
										<MenuItem
											selected={formik.values.subPage === couponsSubPageCode}
											value={couponsSubPageCode}
										>
											Coupons
										</MenuItem>
									</Select>
									<FormHelperText>
										{formik.touched.position && formik.errors.position}
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</>
				)}
				{isCategoryVisible && (
					<>
						<FormLable label='Category' />
						<Grid container rowSpacing={3} columnSpacing={1}>
							<Grid item xs={6}>
								<FormControl
									sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
									error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
								>
									<Select
										size='small'
										fullWidth
										id='category'
										name='category'
										displayEmpty
										// multiple
										value={formik.values.category}
										input={<OutlinedInput />}
										onChange={formik.handleChange}
										error={formik.touched.category && Boolean(formik.errors.category)}
									>
										{allCategorieswithoutPage
											.filter((item: any) => item.status === 1)
											.map((each: any) => (
												<MenuItem
													key={each?.id}
													// selected={formik?.values?.categoryId === each?.id}
													value={each?.id}
												>
													{each?.translation?.en[0]?.name}
												</MenuItem>
											))}
									</Select>
									<FormHelperText>
										{formik.touched.category && formik.errors.category}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl
									sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
									error={formik.touched.category && Boolean(formik.errors.category)}
								>
									<Select
										size='small'
										fullWidth
										id='category'
										name='category'
										dir='rtl'
										displayEmpty
										// multiple
										value={formik.values.category}
										input={<OutlinedInput />}
										onChange={formik.handleChange}
										error={formik.touched.category && Boolean(formik.errors.category)}
									>
										{allCategorieswithoutPage
											.filter((item: any) => item.status === 1)
											.map((each: any) => (
												<MenuItem
													key={each?.id}
													// selected={formik?.values?.category === each?.id}
													value={each?.id}
												>
													{each?.translation?.en[0]?.name}
												</MenuItem>
											))}
									</Select>
									<FormHelperText>
										{formik.touched.categoryId && formik.errors.categoryId}
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</>
				)}
				<FormLable label='Country' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.country && Boolean(formik.errors.country)}
						>
							<Select
								size='small'
								fullWidth
								id='country'
								name='country'
								displayEmpty
								// multiple
								value={selected}
								input={<OutlinedInput />}
								onChange={handleChange}
								error={formik.touched.country && Boolean(formik.errors.country)}
							>
								{/* <MenuItem
									key='all'
									value='all'
									classes={{
										root: isAllSelected ? classes.selectedAll : '',
									}}
								>
									Select All
								</MenuItem> */}

								{allCountry.map((each: any) => (
									<MenuItem key={each.id} value={each.id}>
										{
											each.translation.filter((item: any) => item.languageCode === 'en')[0]
												.name
										}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.country && formik.errors.country}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						{/* Arabic version field */}

						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.country && Boolean(formik.errors.country)}
						>
							<Select
								size='small'
								fullWidth
								dir='rtl'
								id='country'
								name='country'
								// multiple
								displayEmpty
								value={selected}
								input={<OutlinedInput />}
								onChange={handleChange}
								error={formik.touched.country && Boolean(formik.errors.country)}
							>
								{/* <MenuItem
									key='all'
									value='all'
									classes={{
										root: isAllSelected ? classes.selectedAll : '',
									}}
								>
									Select All
								</MenuItem> */}

								{allCountry.map((each: any) => (
									<MenuItem key={each.id} value={each.id}>
										{
											each.translation.filter((item: any) => item.languageCode === 'en')[0]
												.name
										}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.country && formik.errors.country}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				{/* Brand */}
				<FormLable label='Brand' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.brandId && Boolean(formik.errors.brandId)}
						>
							<Select
								size='small'
								fullWidth
								id='brandId'
								name='brandId'
								displayEmpty
								value={formik.values.brandId}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.brandId && Boolean(formik.errors.brandId)}
							>
								{selected.length === 0 ? (
									<p style={{ textAlign: 'center' }}>Please select a country first.</p>
								) : isBrandLoading ? (
									<Box sx={{ display: 'grid', placeItems: 'center' }}>
										<Loader />
									</Box>
								) : (
									allbrandWithoutPage
										?.filter((item: any) => item.status === 1)
										.map((each: any) => (
											<MenuItem key={each?.id} value={each?.id}>
												{each?.brandTranslation?.en[0]?.name}
											</MenuItem>
										))
								)}
							</Select>
							<FormHelperText>
								{formik.touched.brandId && formik.errors.brandId}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.brandId && Boolean(formik.errors.brandId)}
						>
							<Select
								size='small'
								fullWidth
								id='brandId'
								name='brandId'
								displayEmpty
								dir='rtl'
								value={formik.values.brandId}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.brandId && Boolean(formik.errors.brandId)}
							>
								{selected.length === 0 ? (
									<p style={{ textAlign: 'center' }}>Please select a country first.</p>
								) : isBrandLoading ? (
									<Box sx={{ display: 'grid', placeItems: 'center' }}>
										<Loader />
									</Box>
								) : (
									allbrandWithoutPage
										.filter((item: any) => item.status === 1)
										.map((each: any) => (
											<MenuItem key={each?.id} value={each?.id}>
												{each?.brandTranslation?.en[0]?.name}
											</MenuItem>
										))
								)}
							</Select>
							<FormHelperText>
								{formik.touched.brandId && formik.errors.brandId}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>

				{/* position */}
				<FormLable label='Position' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.position && Boolean(formik.errors.position)}
						>
							<Select
								size='small'
								fullWidth
								id='position'
								name='position'
								displayEmpty
								value={formik.values.position}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.position && Boolean(formik.errors.position)}
							>
								<MenuItem selected={formik.values.position === 'TOP'} value='TOP'>
									TOP
								</MenuItem>
								<MenuItem selected={formik.values.position === 'BOTTOM'} value='BOTTOM'>
									BOTTOM
								</MenuItem>
								<MenuItem selected={formik.values.position === 'BOTH'} value='BOTH'>
									BOTH
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.position && formik.errors.position}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.position && Boolean(formik.errors.position)}
						>
							<Select
								size='small'
								fullWidth
								id='position'
								name='position'
								dir='rtl'
								displayEmpty
								value={formik.values.position}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.position && Boolean(formik.errors.position)}
							>
								<MenuItem selected={formik.values.position === 'TOP'} value='TOP'>
									TOP
								</MenuItem>
								<MenuItem selected={formik.values.position === 'BOTTOM'} value='BOTTOM'>
									BOTTOM
								</MenuItem>
								<MenuItem selected={formik.values.position === 'BOTH'} value='BOTH'>
									BOTH
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.position && formik.errors.position}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				<FormLable label='Priority' required={false} />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={12}>
						{/* English version field */}
						<Input
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							fullWidth
							id='priority'
							name='priority'
							placeholder='Enter priority'
							value={formik.values.priority}
							onChange={formik.handleChange}
							error={formik.touched.priority && Boolean(formik.errors.priority)}
							helperText={formik.touched.priority && formik.errors.priority}
						/>
					</Grid>
				</Grid>
				{/* link */}
				<FormLable label='Link' required={false} />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={12}>
						{/* English version field */}
						<Input
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							fullWidth
							id='link'
							name='link'
							placeholder='Enter link'
							value={formik.values.link}
							onChange={formik.handleChange}
							error={formik.touched.link && Boolean(formik.errors.link)}
							helperText={formik.touched.link && formik.errors.link}
						/>
					</Grid>
				</Grid>
				{/* Banner Image */}
				<FormLable label='Banner Image' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						{/* English version field */}
						<UploadImage
							aspect={3 / 1}
							logo={imageEn}
							handleSlug={handleImageEn}
							entity='banners'
							formikImg={(s: string) => setEnImg(s)}
						/>
						<span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.banner && formik.errors.banner && formik.errors.banner}
						</span>
					</Grid>
					<Grid item xs={6}>
						{/* Arabic version field */}
						<UploadImage
							aspect={3 / 1}
							logo={imageAra}
							handleSlug={handleImageAra}
							entity='banners'
							formikImg={(s: string) => setAraImg(s)}
						/>
					</Grid>
				</Grid>

				{/* image alt */}
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='Image Alt' />

						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='imageAlt'
							name='imageAlt'
							multiline
							maxRows={4}
							placeholder='Image alt'
							value={formik.values.imageAlt}
							onChange={formik.handleChange}
							error={formik.touched.imageAlt && Boolean(formik.errors.imageAlt)}
							helperText={formik.touched.imageAlt && formik.errors.imageAlt}
						/>
						{/* <span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.imageAlt && formik.errors.imageAlt && formik.errors.imageAlt}
						</span> */}
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Image Alt (ara)' textAlign='right' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='imageAltAra'
							multiline
							maxRows={4}
							name='imageAltAra'
							type='text'
							dir='rtl'
							placeholder='Image alt arabic'
							value={formik.values.imageAltAra}
							onChange={formik.handleChange}
							error={formik.touched.imageAltAra && Boolean(formik.errors.imageAltAra)}
							helperText={formik.touched.imageAltAra && formik.errors.imageAltAra}
						/>
						{/* <span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.imageAltAra && formik.errors.imageAltAra && formik.errors.imageAltAra}
						</span> */}
					</Grid>
				</Grid>

				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='Image Title' />

						{/* Arabic version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='imageTitle'
							maxRows={4}
							name='imageTitle'
							placeholder='Image Title'
							value={formik.values.imageTitle}
							onChange={formik.handleChange}
							error={formik.touched.imageTitle && Boolean(formik.errors.imageTitle)}
							helperText={formik.touched.imageTitle && formik.errors.imageTitle}
						/>
						{/* <span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.imageTitle && formik.errors.imageTitle && formik.errors.imageTitle}
						</span> */}
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Image Title (ara)' textAlign='right' />

						{/* Arabic version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='imageTitleAra'
							maxRows={4}
							name='imageTitleAra'
							dir='rtl'
							placeholder='Image Title Arabic'
							value={formik.values.imageTitleAra}
							onChange={formik.handleChange}
							error={
								formik.touched.imageTitleAra && Boolean(formik.errors.imageTitleAra)
							}
							helperText={formik.touched.imageTitleAra && formik.errors.imageTitleAra}
						/>
						{/* <span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.imageTitleAra && formik.errors.imageTitleAra && formik.errors.imageTitleAra}
						</span> */}
					</Grid>
				</Grid>

				{/* Status */}
				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
				loading={loadingBtn}
				color='primary'
				variant='contained'
				fullWidth
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const BannerAdd = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector((state: RootState) => state.banner.loading);
	const isAdded = useSelector((state: RootState) => state.banner.isAdded);
	const isUpdated = useSelector((state: RootState) => state.banner.isUpdated);

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('banner-management');
			dispatch(clearData());
		}
	}, [isAdded, isUpdated]);

	useEffect(() => {
		if (id) {
			dispatch(getBannerById(id));
		}
	}, [id]);

	return (
		<TableLayout>
			<Container maxWidth='lg' className='addScroll'>
				<Header title={id ? 'Edit Banner' : 'Add Banner'} />
				<Box>
					{loading ? (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								backgroundColor: 'white',
								opacity: '0.7',
								zIndex: '999',
								minHeight: 'calc(100vh - 80px)',
							}}
						>
							<Loader />
						</Box>
					) : (
						<WithMaterialUI />
					)}
				</Box>
			</Container>
		</TableLayout>
	);
};
export default BannerAdd;
