/* eslint-disable no-lone-blocks */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICategoryObject } from 'Interfaces/Category';
import toast from 'react-hot-toast';
import { go } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';
import validateSeoAndTranslation from 'utils/helper';
import { ApiParams } from './constant';

export const getAllCategory = createAsyncThunk(
	'getAllCategory',
	async ({ page, statusFilter, q, perPage }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/categories', {
				params: {
					page,
					status: statusFilter,
					q,
					perPage,
				},
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const getallCategorieswithoutPage = createAsyncThunk(
	'getallCategorieswithoutPage',
	async ({ page }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/categories?perPage=${300}`, {
				params: { page },
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const getCategoryById = createAsyncThunk(
	'getCategoryById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/categories/${id}`, {
				params: {
					include: ApiParams.getCategoryByIdParam,
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const addCategory = createAsyncThunk(
	'addCategory',
	async (backendObject: ICategoryObject, thunkAPI) => {
		if (validateSeoAndTranslation(backendObject.seoAndTranslation, thunkAPI)) {
			return thunkAPI.rejectWithValue('Validation failed');
		}

		// If no errors, continue with the try block
		try {
			const response = await axiosInstance.post('admin/categories', backendObject);
			if (response.status === 200) {
				toast.success('Category added successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const editCategory = createAsyncThunk(
	'editCategory',
	async (
		{ backendObject, id }: { backendObject: ICategoryObject; id: string },
		thunkAPI
	) => {
		if (validateSeoAndTranslation(backendObject.seoAndTranslation, thunkAPI)) {
			return thunkAPI.rejectWithValue('Validation failed');
		}

		try {
			const response = await axiosInstance.put(
				`admin/categories/${id}`,
				backendObject
			);
			if (response.status === 204) {
				toast.success('Category updated successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const DeleteCategory = createAsyncThunk(
	'DeleteCategory',
	async (
		{
			id,
			sendStatus,
			statusFilter,
			query,
		}: { id: string; sendStatus: number; statusFilter: number; query: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/categories/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				{
					toast.success(
						sendStatus
							? 'Category status changed to Active'
							: 'Category status changed to Inactive'
					);
				}
				const { category }: any = thunkAPI.getState();
				const page = category.pagination.currentPage;
				thunkAPI.dispatch(
					getAllCategory({ page, isRefresh: true, statusFilter, q: query })
				);
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const searchCategory = createAsyncThunk(
	'searchCategory',
	async ({ q, status }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/categories', {
				params: {
					q,
					status,
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
