// import { BrandTranslation } from './../../Interfaces/Brand';
import { createSlice } from '@reduxjs/toolkit';
import {
	AddBrand,
	DeleteBrand,
	getAllBrand,
	getAllBrandsWithoutPage,
	getBrandById,
	searchBrand,
	UpdateBrand,
} from 'api/brand';
import { IBrand } from 'Interfaces/Brand';
import { groupBy, orderBy } from 'lodash';

const initialState: IBrand = {
	allbrand: [],
	allbrandWithoutPage: [],
	error: '',
	isAdded: false,
	isUpdated: false,
	singleBrand: {
		id: '',
		status: 0,
		country: [],
		brandTranslation: {
			en: [
				{
					id: 0,
					brandID: 0,
					languageCode: '',
					name: '',
					imageSlug: '',
					description: '',
				},
			],
			ara: [
				{
					id: 0,
					brandID: 0,
					languageCode: '',
					name: '',
					imageSlug: '',
					description: '',
				},
			],
		},
		seo: [],
		seoAndTranslation: [],
	},
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 155,
		total: 0,
	},
};

export const brandSlice = createSlice({
	name: 'brand',
	initialState,
	reducers: {
		// allbrand
		emptySingleBrand: state => {
			state.singleBrand = initialState.singleBrand;
		},
		toggleLoading: state => {
			state.loading = !state.loading;
		},
		toggleStatus: (state, action) => {
			state.allbrand = state.allbrand.map((d: any) =>
				d.id === action.payload
					? { ...d, status: !d.status, views: [{ ...d.views, isTrending: 0 }] }
					: { ...d }
			);
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllBrand.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllBrand.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach(
					(each: any) =>
						each?.brandTranslation.length > 0 &&
						array.push({
							...each,
							brandTranslation: groupBy(each?.brandTranslation, 'languageCode'),
						})
				);

				state.allbrand = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllBrand.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getAllBrandsWithoutPage.pending, state => {
				state.loading = true;
			})
			.addCase(getAllBrandsWithoutPage.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];

				action.payload.data.forEach(
					(each: any) =>
						each?.brandTranslation.length > 0 &&
						array.push({
							...each,
							brandTranslation: groupBy(each?.brandTranslation, 'languageCode'),
						})
				);

				state.allbrandWithoutPage = orderBy(
					array,
					[item => item.brandTranslation.en[0].name.toLowerCase()],
					['asc']
				);
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllBrandsWithoutPage.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user

			.addCase(searchBrand.pending, state => {
				state.loading = true;
			})
			.addCase(searchBrand.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						brandTranslation: groupBy(each?.brandTranslation, 'languageCode'),
					})
				);

				state.allbrand = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchBrand.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add brand
			.addCase(AddBrand.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddBrand.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddBrand.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			// update brand
			.addCase(UpdateBrand.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateBrand.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateBrand.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;

				state.error = action.error.message;
			})

			// get single brand
			.addCase(getBrandById.pending, state => {
				state.loading = true;
			})
			.addCase(getBrandById.fulfilled, (state, action) => {
				state.loading = false;
				state.singleBrand = {
					...action?.payload?.data,
					brandTranslation: groupBy(
						action?.payload?.data?.brandTranslation,
						'languageCode'
					),
				};
			})
			.addCase(getBrandById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// change status

			.addCase(DeleteBrand.pending, state => {
				state.loading = false;
			})
			.addCase(DeleteBrand.fulfilled, state => {
				state.loading = false;
			})
			.addCase(DeleteBrand.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleBrand, toggleLoading, toggleStatus, clearData } =
	brandSlice.actions;

export default brandSlice.reducer;
