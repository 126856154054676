import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBrandObject } from 'Interfaces/Brand';
import toast from 'react-hot-toast';
import { go } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';
import validateSeoAndTranslation from 'utils/helper';
import { ApiParams } from './constant';

export const getAllBrand = createAsyncThunk(
	'getAllBrand',
	async ({ page, statusFilter, q, perPage }: any) => {
		try {
			const response = await axiosInstance.get('admin/brands', {
				params: {
					page,
					status: statusFilter,
					q,
					include: 'country,seo',
					perPage,
				},
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
export const getAllBrandsWithoutPage = createAsyncThunk(
	'getAllBrandsWithoutPage',
	async ({ page, countries, addCoupon }: any) => {
		try {
			let url = `admin/brands?perPage=${300}`;

			if (addCoupon) {
				url = `admin/brands/brand-country-filter?perPage=${300}`;
			}
			const response = await axiosInstance.get(url, {
				params: {
					page,
					// countries: countries.toString(),
					include: 'country,seo',
					paginate: 'false',
				},
			});
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const AddBrand = createAsyncThunk(
	'AddBrand',
	async (backendObject: IBrandObject, thunkAPI) => {
		if (validateSeoAndTranslation(backendObject.seoAndTranslation, thunkAPI)) {
			return thunkAPI.rejectWithValue('Validation failed');
		}

		try {
			const response = await axiosInstance.post(`admin/brands`, backendObject);
			if (response.status === 200) {
				toast.success('Brand Added successfully');
				thunkAPI.dispatch(getAllBrand({}));
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdateBrand = createAsyncThunk(
	'UpdateBrand',
	async (
		{ backendObject, id }: { backendObject: IBrandObject; id: string },
		thunkAPI
	) => {
		if (validateSeoAndTranslation(backendObject.seoAndTranslation, thunkAPI)) {
			return thunkAPI.rejectWithValue('Validation failed');
		}

		try {
			const response = await axiosInstance.put(
				`admin/brands/${id}`,
				backendObject
			);
			if (response.status === 200) {
				toast.success('Brand Updated successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const DeleteBrand = createAsyncThunk(
	'DeleteBrand',
	async (
		{
			id,
			sendStatus,
			statusFilter,
			query,
		}: { id: string; sendStatus: number; statusFilter: number; query: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/brands/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Brand status changed to Active'
						: 'Brand status changed to Inactive'
				);
				const { brand }: any = thunkAPI.getState();
				const page = brand.pagination.currentPage;
				thunkAPI.dispatch(
					getAllBrand({ page, isRefresh: true, statusFilter, q: query })
				);
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getBrandById = createAsyncThunk(
	'getBrandById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/brands/${id}`, {
				params: {
					include: ApiParams.getBrandByIdParam,
				},
			});
			if (response.status === 200) {
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const searchBrand = createAsyncThunk(
	'searchBrand',
	async ({ q, status }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/brands', {
				params: {
					q,
					status,
					include: 'country,seo',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
