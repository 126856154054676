import toast from 'react-hot-toast';
import { couponsCode, offersCode } from './constant';

const validateSeoAndTranslation = (seoAndTranslation: any[], thunkAPI: any) => {
	let validationErrorOccurred = false;

	seoAndTranslation.forEach((item: any) => {
		if (item.page === offersCode) {
			const cleanedDescription = item.description.replace(/<[^>]+>/g, '').trim();
			if (
				!cleanedDescription ||
				item.description === null ||
				!item.seoTitle ||
				item.seoTitle === null ||
				!item.seoDescription ||
				item.seoDescription === null
			) {
				toast.error(
					`Offers ${
						item.languageCode === 'en' ? 'English' : 'Arabic '
					}: All Fields  are required.`
				);
				thunkAPI.rejectWithValue('Offers : All Fields  are required');
				validationErrorOccurred = true;
			}
		} else if (item.page === couponsCode) {
			const cleanedDescription = item.description.replace(/<[^>]+>/g, '').trim();
			if (
				!cleanedDescription ||
				item.description === null ||
				!item.seoTitle ||
				item.seoTitle === null ||
				!item.seoDescription ||
				item.seoDescription === null
			) {
				toast.error(
					`Coupons ${
						item.languageCode === 'en' ? 'English' : 'Arabic '
					}: All Fields  are required.`
				);
				thunkAPI.rejectWithValue('Coupons: All Fields  are required.');
				validationErrorOccurred = true;
			}
		}
	});

	return validationErrorOccurred;
};

export default validateSeoAndTranslation;
