/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import {
	Box,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { AddPageSeo, getSeoByPage, UpdatePageSeo } from 'api/pageSeo';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData, emptyPageSeo } from 'redux/pageSeo/seoSlice';
import { RootState } from 'redux/store';
import UploadImage from 'utils/UploadImage';

import FormLable from '../../../components/common/FormLable';
import validationSchema from './validation';

// const useStyles = makeStyles(theme => ({
// 	selectedAll: {
// 		backgroundColor: 'rgba(186, 84, 245, 0.08)',
// 		'&:hover': {
// 			backgroundColor: 'rgba(186, 84, 245, 0.12)',
// 		},
// 	},
// }));

const pages = [
	{ name: 'Home', value: 'home' },
	{ name: 'About us', value: 'aboutUs' },
	{ name: 'Contact Us', value: 'contactUs' },
	{ name: 'Advertise With Us', value: 'advertiseWithUs' },
	{ name: 'FAQ', value: 'faq' },
	{ name: 'Privacy policy', value: 'privacyPolicy' },
	{ name: 'Terms & Conditions', value: 'tnc' },
	{ name: 'Sitemap', value: 'sitemap' },
	{ name: 'Brands', value: 'brands' },
	{ name: 'Categories', value: 'categories' },
	{ name: 'Coupons', value: 'coupons' },
	{ name: 'Offers', value: 'offers' },
];

// const pagesAra = [
// 	{ name: 'مسكن', value: 'home' },
// 	{ name: 'فئة', value: 'category' },
// 	{ name: 'ماركة', value: 'brand' },
// 	{ name: 'معلومات عنا', value: 'aboutUs' },
// 	{ name: 'سياسة خاصة', value: 'privacyPolicy' },
// ];

const WithMaterialUI = () => {
	const { pageName } = useParams();

	const { pageSeo, loadingBtn } = useSelector(
		(state: RootState) => state.pageSeo
	);

	const [imageEn, setImageEn] = useState<string>(
		'' ||
			pageSeo?.translation?.filter((item: any) => item.languageCode === 'en')[0]
				.image.path
	);
	const [imageAra, setImageAra] = useState<string>(
		'' ||
			pageSeo?.translation?.filter((item: any) => item.languageCode === 'ara')[0]
				.image.path
	);

	const handleImageEn = (data: string) => {
		setImageEn(data);
	};
	const handleImageAra = (data: string) => {
		setImageAra(data);
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearData());
	}, []);

	const formik = useFormik({
		initialValues: {
			status: pageSeo?.id ? (pageSeo.status === 1 ? 1 : 0) : 0,
			title:
				pageSeo?.id !== ''
					? pageSeo?.translation?.filter(
							(item: any) => item.languageCode === 'en'
					  )[0].title
					: '',
			titleAra:
				pageSeo?.id !== ''
					? pageSeo?.translation?.filter(
							(item: any) => item.languageCode === 'ara'
					  )[0].title
					: '',
			description:
				pageSeo?.id !== ''
					? pageSeo?.translation?.filter(
							(item: any) => item.languageCode === 'en'
					  )[0].description
					: '',
			descriptionAra:
				pageSeo?.id !== ''
					? pageSeo?.translation?.filter(
							(item: any) => item.languageCode === 'ara'
					  )[0].description
					: '',
			keywords:
				pageSeo?.id !== ''
					? pageSeo?.translation?.filter(
							(item: any) => item.languageCode === 'en'
					  )[0].keywords
					: '',
			keywordsAra:
				pageSeo?.id !== ''
					? pageSeo?.translation?.filter(
							(item: any) => item.languageCode === 'ara'
					  )[0].keywords
					: '',
			pageName: pageSeo?.id ? pageSeo?.pageName : '',
			imageSlug:
				pageSeo?.id !== ''
					? pageSeo?.translation?.filter(
							(item: any) => item.languageCode === 'en'
					  )[0].image.slug
					: '',
			imageSlugAra:
				pageSeo?.id !== ''
					? pageSeo?.translation?.filter(
							(item: any) => item.languageCode === 'ara'
					  )[0].image.slug
					: '',
			imageAlt:
				pageSeo?.translation?.filter((item: any) => item.languageCode === 'en')[0]
					?.imageAlt || '',

			imageAltAra:
				pageSeo?.translation?.filter((item: any) => item.languageCode === 'ara')[0]
					?.imageAlt || '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: values => {
			const backendObject = {
				pageName: values.pageName,
				status: values.status,
				translation: [
					{
						languageCode: 'en',
						title: values.title,
						description: values.description,
						keywords: values.keywords,
						image: values.imageSlug,
						imageAlt: values.imageAlt,
					},
					{
						languageCode: 'ara',
						title: values.titleAra,
						description: values.descriptionAra,
						keywords: values.keywordsAra,
						image: values.imageSlug ? values.imageSlug : values.imageSlugAra,
						imageAlt: values.imageAltAra,
					},
				],
			};
			if (pageName) {
				dispatch(UpdatePageSeo({ backendObject }));
			} else {
				dispatch(AddPageSeo(backendObject));
			}
		},
	});

	const setEnImg = (slug: string) => {
		formik.setFieldValue('imageSlug', slug);
	};
	const setAraImg = (slug: string) => {
		formik.setFieldValue('imageSlugAra', slug);
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<Grid container rowSpacing={4} columnSpacing={3}>
					<Grid item xs={12} sx={{ marginTop: '1rem' }}>
						{/* Arabic version field */}
						<FormLable label='Page Name' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.pageName && Boolean(formik.errors.pageName)}
						>
							<Select
								size='small'
								id='pageName'
								name='pageName'
								displayEmpty
								value={formik.values.pageName}
								input={<OutlinedInput />}
								onChange={e => {
									const { value } = e.target;
									formik.setValues({
										...formik.values,
										pageName: value,
									});
								}}
								disabled={pageSeo?.id}
								error={formik.touched.pageName && Boolean(formik.errors.pageName)}
							>
								{pages.map((each: any) => (
									<MenuItem key={each.value} value={each.value}>
										{each.name}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.pageName && formik.errors.pageName}
							</FormHelperText>
						</FormControl>
					</Grid>
					{/* <Grid item xs={6} className='my-grid'>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.pageName && Boolean(formik.errors.pageName)}
						>
							<Select
								size='small'
								dir='rtl'
								id='pageName'
								name='pageName'
								displayEmpty
								defaultValue={formik.values.pageName}
								value={formik.values.pageName}
								input={<OutlinedInput />}
								onChange={e => {
									const { value } = e.target;
									formik.setValues({
										...formik.values,
										pageName: value,
									});
								}}
								error={formik.touched.pageName && Boolean(formik.errors.pageName)}
							>
								{pagesAra.map((each: any) => (
									<MenuItem key={each.Name} value={each.value}>
										{each.name}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.pageName && formik.errors.pageName}
							</FormHelperText>
						</FormControl>
					</Grid> */}
					<Grid item xs={6} className='my-grid'>
						{/* English version field */}
						<FormLable label='Title' />
						<Input
							type='text'
							multiline
							fullWidth
							id='title'
							name='title'
							value={formik.values.title}
							onChange={formik.handleChange}
							error={formik.touched.title && Boolean(formik.errors.title)}
							helperText={formik.touched.title && formik.errors.title}
						/>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<Input
							fullWidth
							id='titleAra'
							name='titleAra'
							type='text'
							multiline
							dir='rtl'
							value={formik.values.titleAra}
							onChange={formik.handleChange}
							error={formik.touched.titleAra && Boolean(formik.errors.titleAra)}
							helperText={formik.touched.titleAra && formik.errors.titleAra}
						/>
					</Grid>
					<Grid item xs={6} className='my-grid'>
						{/* English version field */}
						<FormLable label='Description' />
						<Input
							type='text'
							multiline
							fullWidth
							id='description'
							name='description'
							value={formik.values.description}
							onChange={formik.handleChange}
							error={formik.touched.description && Boolean(formik.errors.description)}
							helperText={formik.touched.description && formik.errors.description}
						/>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<Input
							fullWidth
							id='descriptionAra'
							name='descriptionAra'
							type='text'
							multiline
							dir='rtl'
							value={formik.values.descriptionAra}
							onChange={formik.handleChange}
							error={
								formik.touched.descriptionAra && Boolean(formik.errors.descriptionAra)
							}
							helperText={
								formik.touched.descriptionAra && formik.errors.descriptionAra
							}
						/>
					</Grid>
					<Grid item xs={6} className='my-grid'>
						{/* English version field */}
						<FormLable label='Keywords' />
						<Input
							type='text'
							multiline
							fullWidth
							id='keywords'
							name='keywords'
							value={formik.values.keywords}
							onChange={formik.handleChange}
							error={formik.touched.keywords && Boolean(formik.errors.keywords)}
							helperText={formik.touched.keywords && formik.errors.keywords}
						/>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<Input
							fullWidth
							id='keywordsAra'
							name='keywordsAra'
							type='text'
							multiline
							dir='rtl'
							value={formik.values.keywordsAra}
							onChange={formik.handleChange}
							error={formik.touched.keywordsAra && Boolean(formik.errors.keywordsAra)}
							helperText={formik.touched.keywordsAra && formik.errors.keywordsAra}
						/>
					</Grid>
				</Grid>

				<FormLable label='Page Image' />
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<UploadImage
							logo={imageEn}
							handleSlug={handleImageEn}
							entity='seo'
							formikImg={(s: string) => setEnImg(s)}
						/>
						<span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.imageSlug &&
								formik.errors.imageSlug &&
								formik.errors.imageSlug}
						</span>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='imageAlt'
							name='imageAlt'
							placeholder='Image Alt'
							value={formik.values.imageAlt}
							onChange={formik.handleChange}
						/>
					</Grid>
					<Grid item xs={2} />

					<Grid item xs={2}>
						<UploadImage
							logo={imageAra}
							handleSlug={handleImageAra}
							entity='seo'
							formikImg={(s: string) => setAraImg(s)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='imageAltAra'
							name='imageAltAra'
							placeholder='Image Alt'
							dir='rtl'
							value={formik.values.imageAltAra}
							onChange={formik.handleChange}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<FormLable label='Status' />
					<FormControl
						sx={{ width: '100%' }}
						error={formik.touched.status && Boolean(formik.errors.status)}
					>
						<Select
							fullWidth
							size='small'
							id='status'
							name='status'
							displayEmpty
							value={formik.values.status}
							input={<OutlinedInput />}
							onChange={formik.handleChange}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<MenuItem selected={formik.values.status === 1} value={1}>
								Active
							</MenuItem>
							<MenuItem selected={formik.values.status === 0} value={0}>
								Inactive
							</MenuItem>
						</Select>
						<FormHelperText>
							{formik.touched.status && formik.errors.status}
						</FormHelperText>
					</FormControl>
				</Grid>
			</Box>

			<Button
				sx={{ marginTop: '3rem', marginBottom: '1rem' }}
				color='primary'
				variant='contained'
				fullWidth
				type='submit'
				loading={loadingBtn}
			>
				Submit
			</Button>
		</form>
	);
};

const PageSeoAdd = () => {
	// const dispatch = useDispatch();
	const { pageName } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const loading = useSelector((state: RootState) => state.pageSeo.loading);
	const isAdded = useSelector((state: RootState) => state.pageSeo.isAdded);
	const isUpdated = useSelector((state: RootState) => state.pageSeo.isUpdated);

	useEffect(() => {
		if (pageName) {
			dispatch(getSeoByPage(pageName));
		}
		return () => {
			dispatch(emptyPageSeo());
		};
	}, [pageName]);

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/page-seo');
		}
	}, [isAdded, isUpdated]);

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title={pageName ? 'Edit Seo' : 'Add Seo'} />
				<Box>
					{loading ? (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								backgroundColor: 'white',
								opacity: '0.7',
								zIndex: '999',
								minHeight: 'calc(100vh - 80px)',
							}}
						>
							<Loader />
						</Box>
					) : (
						<WithMaterialUI />
					)}
				</Box>
			</Container>
		</TableLayout>
	);
};
export default PageSeoAdd;
