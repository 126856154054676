import { createSlice } from '@reduxjs/toolkit';
import {
	AddCoupon,
	getAllCoupon,
	getCouponsById,
	getCouponsForCashback,
	searchCoupon,
	UpdateCoupon,
} from 'api/coupon';
import { ICoupon } from 'Interfaces/Coupons';
import { groupBy } from 'lodash';

const initialState: ICoupon = {
	allCoupons: [],
	error: '',
	isAdded: false,
	isUpdated: false,
	singleCoupon: {
		id: '',
		partnerType: '',
		category: [],
		translation: {},
		brand: {
			id: '',
			// iconSlug: '',
			status: -1,
			brandId: '',
			country: [],
			brandTranslation: {
				en: [
					{
						id: 0,
						brandID: 0,
						languageCode: '',
						name: '',
						imageSlug: '',
						description: '',
					},
				],
				ara: [
					{
						id: 0,
						brandID: 0,
						languageCode: '',
						name: '',
						imageSlug: '',
						description: '',
					},
				],
			},
			seo: [],
			seoAndTranslation: [],
		},
		partner: '',
		status: -1,
		rewardValue: '',
		rewardType: -1,
		country: [],
		expiryDate: '',
		couponCode: '',
		link: '',
		seoTitle: '',
		// seoKeywords: '',
		seoMetas: '',
	},
	loading: false,
	cashbackCoupons: undefined,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const couponSlice = createSlice({
	name: 'coupon',
	initialState,
	reducers: {
		emptySingleCoupon: state => {
			state.singleCoupon = initialState.singleCoupon;
			state.cashbackCoupons = initialState.cashbackCoupons;
		},
		toggleLoading: state => {
			state.loading = !state.loading;
		},
		toggleStatus: (state, action) => {
			state.allCoupons = state.allCoupons.map((d: any) =>
				d.id === action.payload ? { ...d, status: !d.status } : { ...d }
			);
		},
		clearData: state => {
			state.loadingBtn = initialState.loadingBtn;
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			//  add coupon
			.addCase(AddCoupon.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddCoupon.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddCoupon.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;

				state.error = action.error.message;
			})

			// update coupon

			.addCase(UpdateCoupon.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateCoupon.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateCoupon.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;

				state.error = action.error.message;
			})

			// all coupons
			.addCase(getAllCoupon.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllCoupon.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action?.payload?.data?.forEach((each: any) =>
					array.push({
						...each,
						brand: {
							...each?.brand,
							brandTranslation: groupBy(each?.brand?.brandTranslation, 'languageCode'),
						},
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allCoupons = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllCoupon.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search coupons

			.addCase(searchCoupon.pending, state => {
				state.loading = true;
			})
			.addCase(searchCoupon.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						brand: {
							...each.brand,
							brandTranslation: groupBy(each?.brand?.brandTranslation, 'languageCode'),
						},
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allCoupons = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchCoupon.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// get coupons by id

			.addCase(getCouponsById.pending, state => {
				state.loading = true;
			})
			.addCase(getCouponsById.fulfilled, (state, action) => {
				state.loading = false;
				state.singleCoupon = {
					...action?.payload?.data,
					translation: groupBy(action?.payload?.data?.translation, 'languageCode'),
				};
			})
			.addCase(getCouponsById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// Cashback Coupon

			.addCase(getCouponsForCashback.pending, state => {
				state.loading = true;
			})
			.addCase(getCouponsForCashback.fulfilled, (state, action) => {
				state.loading = false;
				state.cashbackCoupons = action?.payload?.data;
			})
			.addCase(getCouponsForCashback.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleCoupon, toggleLoading, toggleStatus, clearData } =
	couponSlice.actions;

export default couponSlice.reducer;
