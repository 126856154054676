import moment from 'moment';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { io } from 'socket.io-client';
import axiosInstance from 'utils/axiosInstance';
import { getFromLocal } from 'utils/localStorage';

import { SocketEvents } from './constant';

export type TChat = {
	id: number;
	threadId: number;
	sender: {
		id: number;
		firstName: string;
		profileImage: { path: string } | null;
	};
	message: string;
	createdAt: string;
};

const useChatBox = (threadId: number) => {
	const [scrollBox, setScrollBox] = useState<HTMLDivElement>();
	const [chats, setChats] = useState<TChat[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [chatsMeta, setChatsMeta] = useState<{
		currentPage: number;
		perPage: number;
		total: number;
		totalPages: number;
	}>();
	const scrollBoxRef = useCallback((node: HTMLDivElement) => {
		if (node) {
			setScrollBox(node);
		}
	}, []);

	const socket = useMemo(
		() =>
			io(`${process.env.REACT_APP_SOCKET_URL}`, {
				transports: ['websocket', 'polling'],
				withCredentials: true,
				auth: {
					authorization: getFromLocal('user')?.token || '',
				},
			}),
		[]
	);

	const handleSendMessage = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const form = e.target as HTMLFormElement;
		const user = getFromLocal('user');
		const data = Object.fromEntries(new FormData(form));
		if (String(data.message).trim().length > 0) {
			const dummyMessageId = user.user.id + chats.length;
			const payload = {
				message: String(data.message),
				threadId,
			};
			setChats(prev => [
				...prev,
				{
					...payload,
					sender: {
						id: user.user.id,
						firstName: user.user.firstName,
						profileImage: user.user.profileImage ?? null,
					},
					id: dummyMessageId,
					createdAt: new Date().toISOString(),
				},
			]);
			socket.emit(SocketEvents.CHAT_CONNECT, payload);
			form.reset();
		}
	};
	const [currentPage, setCurrentPage] = useState<number>(1);
	useEffect(() => {
		if (chats && chats.length && scrollBox && currentPage === 1) {
			scrollBox.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}
	}, [chats, scrollBox, currentPage]);
	const fetchChats = async (page: number) => {
		try {
			setIsLoading(true);
			const res = await axiosInstance.get(
				`/admin/thread/${threadId}?perPage=15&page=${page}`
			);
			const messages = res?.data?.data;
			setChatsMeta(res.data.meta.pagination);
			if (messages.length > 0) {
				setChats(prev => [...prev, ...messages]);
			}
		} catch (error) {
			console.log(error);
		}
		setIsLoading(false);
	};
	useEffect(() => {
		fetchChats(currentPage);
	}, [currentPage]);
	const isFirstRender = useRef(true);
	const { inView, ref: firstChatRef } = useInView();
	useEffect(() => {
		if (inView && chatsMeta && chatsMeta?.currentPage < chatsMeta?.totalPages) {
			if (isFirstRender.current) {
				isFirstRender.current = false;
			} else {
				setCurrentPage(prev => prev + 1);
			}
		}
	}, [inView]);

	useEffect(() => {
		socket.on('connect', () => {
			socket.emit(SocketEvents.CHAT_JOIN_ROOM, { threadId });
		});
		socket.on(SocketEvents.CHAT_MESSAGE, message => {
			setChats(prev => [...prev, message]);
		});
		return () => {
			socket.emit(SocketEvents.CHAT_DISCONNECT, { threadId });
			socket.off('connect');
		};
	}, [socket]);
	const getFormattedDate = (initDate: string) => {
		let date = '';
		const momentDate = moment(initDate);
		if (momentDate.isSame(moment(), 'date')) {
			date = 'Today';
		} else if (momentDate.isSame(moment().subtract(1, 'day'), 'date')) {
			date = 'Yesterday';
		} else {
			date = momentDate.format('DD MMM, YYYY');
		}
		return date;
	};
	return {
		chats,
		handleSendMessage,
		scrollBoxRef,
		getFormattedDate,
		firstChatRef,
		isLoading,
	};
};

export default useChatBox;
